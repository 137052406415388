const JAN_2021 = {
    "title": "Jan 2021",
    "author": "Richard Li",
    "content": [
        {
            "id": 1,
            "genre": "anime",
            "text": [
                {
                    "id": 1,
                    "name": "The Rising of the Shield Hero (2019)",
                    "desc": "This is a story about being a hero with very tough initial conditions. As the hero of shield, " +
                        "the main character - Naofumi, have no knowledge about the new world. Without followers, he cannot " +
                        "even gain sufficient experience points and money to be stronger. Gradually, he meets up his friends and " +
                        "gradually grows outstanding.",
                    "rank": 4.2
                }
            ]
        }
    ]
}

export default JAN_2021;