import React from 'react';

const Footer = (props) => {
    return (
        <div className="col-12 footer">
            <p>Copyright by Richard Li, 2020</p>
        </div>
    )
}

export default Footer;