import logo from './logo.svg';
import './App.css';
import React from 'react'
import Main from './components/MainComponent'
import Header from "./components/HeaderComponent";
import Footer from "./components/FooterComponent";

function App() {
  return (
    <div>
        <Header/>

        <div className="container">
            <Main/>
        </div>


        <Footer/>
    </div>
  );
}

export default App;
