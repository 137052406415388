import React, {Component} from 'react';
import {NavLink} from 'reactstrap';

import Display from "./DisplayComponent";
import DEC_2020 from "../shared/202012";
import JAN_2021 from "../shared/202101";

class HomePage extends Component{

    constructor(props) {
        super(props);
        this.state = {
            time: 202012,
            data: DEC_2020
        }
    }

    setArticle(newtime){
        console.log(newtime);
        this.setState({time: newtime});
        switch(newtime){
            case 202101:
                this.setState({data: JAN_2021});
                break;
            default:
                this.setState({data: DEC_2020});
                break;
        }
    }

    render(){
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-2">
                        Navigation
                        <NavLink onClick={()=>(this.setArticle(202101))}>01-2021</NavLink>
                        <NavLink onClick={()=>(this.setArticle(202012))}>12-2020</NavLink>
                    </div>
                    <div className="col-12 col-lg-10">
                        <Display data={this.state.data}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default HomePage;