const x0001 = {
    "title": "The first trial of fullstack",
    "author": "Richard Li",
    "time": "Dec 31, 2020 UTC+8:00 13:43",
    "article": [
        {
            "id": 1,
            "content": "This is the walk-through of me to get here and be ready to be better in website application " +
                "design and development. At the time point, what had I achieved is not much, but I believed it is way " +
                "more than what had I experienced in my Bachelor and Master's studies."
        },
        {
            "id": 2,
            "content": "The path way I am following is on Coursera, a course delivered by HKUST and sponsored by HKU. " +
                "The specialization course series contains two parts, the first one is to develop fullstack web application " +
                "utilizing Bootstrap, React.js, React Native, Node.js, and MongoDB. The second one is about fullstack cross-" +
                "platform application including mobile, which takes Angular.js and some other techniques to be discovered " +
                "when I finished the first one."
        },
        {
            "id": 3,
            "type": "subtitle",
            "content": "1 - Construct my personal page"
        },
        {
            "id": 4,
            "content": "The first goal for me is to use the course #1, which is plain HTML with Bootstrap CSS, and a little " +
                "for JQuery and SCSS. In my understanding, Bootstrap is well designed for grid based design, and the " +
                "compatibility of the designed website would be easier to control. Therefore, I used my previous knowledge " +
                "in web development to generate my contents."
        },
        {
            "id": 5,
            "content": "It is amusing that I have encountered several problems for even the first step. First it is about npm," +
                " the package management. To follow the instructions in the courses, I always receive WARNINGs about dependency " +
                "resolve failure. This is still a TODO item as I can just follow stackoverflow to use --force instruction but " +
                "not knowing better ideas. Secondly, it is about the ordering of referenced JS files. The Bootstrap, JQuery, and " +
                "Poppers have orders to be cited; however, it is not well described for the course. It cost me very long time " +
                "to troubleshoot the static website on my own host."
        },
        {
            "id": 6,
            "content": "Then, naturally I want to put my project online. I have bought my domain name from cloud service " +
                "provider in mainland and did some registration. This process is shorted than what I have expected. Although " +
                "the information of mine may leak in a hilarious way. I chose to host my personal page on Firebase, which " +
                "I got to know from a hackathon workshop. It is also, humm, very pleasing to solve the problems that I cannot" +
                " imagine before."
        },
        {
            "id": 7,
            "content": "On firebase, the API keys are set in a way that I cannot understand. However, by coping and pasting, " +
                "I kind of finished the setting. After than, I find the CSS did not apply to what I have posted online. " +
                "Also, the JavaScript effect (like collapse, or icons in font-awesome library) did not appear as well. " +
                "After personal experiments, I find it is necessary to include CDN hosted files in the static files."
        },
        {
            "id": 8,
            "type": "subtitle",
            "content": "2 - From personal page to blog"
        },
        {
            "id": 9,
            "content": "Stepping into the course #2, React is introduced and I have weapons to process repeating jobs like, " +
                "adding the headers and footers, or load some of the static files. I have decided to add the blog function at " +
                "that time."
        },
        {
            "id": 10,
            "content": "For the DNS thing, I have stuck with how to start a react app with very plain project, or how can " +
                "I just put the app in a subdomain. Luckily, having setting the www domain of my project, I add another " +
                "lines of records for blog subdomain. To understand the public folder and src folder, and to use the function of " +
                "npm start build are the things I find most useful for beginners."
        },
        {
            "id": 11,
            "type": "subtitle",
            "content": "End of December, End of 2020"
        },
        {
            "id": 12,
            "content": "Actually after doing this I have spent around one-month time in learning and exploring apart from " +
                "my original Master's study. The progress is low but it is inspiring. Hopefully I can graduate on time, and " +
                "have a fulfilling job in the year 2021."
        }
    ]
}

export default x0001;