import React, {Component} from 'react';
import {Card, CardBody, CardImg, CardTitle} from 'reactstrap';
import DEC_2020 from '../shared/202012';

import "./DisplayComponent.css";

function GenerateGenreContent({genre}){
    const text = genre.map((item) => {
        return (
            <li>
                <Card>
                    <CardTitle>
                        <h3>{item.name}</h3>
                    </CardTitle>
                    <CardBody>
                        <div className="row">
                            {(item.image != null ?
                                <React.Fragment>
                                <div className="col-3 d-none d-md-block">
                                    <img src={process.env.PUBLIC_URL+"/assets/"+item.image}
                                         alt={item.name}
                                         width="100px"
                                         className="-align-center"
                                    />
                                </div>
                                <div className="col-12 col-md-9 para">
                                    <p>{item.desc}</p>
                                    <p>Rating: {item.rank}</p>
                                </div>
                                </React.Fragment>
                                :

                                <div className="col-12 para">
                                <p>{item.desc}</p>
                                <p>Rating: {item.rank}</p>
                                </div>
                            )}
                        </div>
                    </CardBody>
                </Card>
            </li>
        );
    })
    return (
        <ul className="list-unstyled">
            {text}
        </ul>
    );
}

function GenerateGenre({data}){
    console.log(data)
    const text = data.map((genre)=>{
        return (
            <div className="col-12">
                <br/>
                <h3>{genre.genre}</h3>
                <GenerateGenreContent genre={genre.text}/>
            </div>
        )
    });
    return (
        <div className="container">
            {text}
        </div>
    );
}

class Display extends Component{

    render(){
        return(
            <div>
                <h3>{this.props.data.title}</h3>
                <GenerateGenre data={this.props.data.content}/>
            </div>
        )
    }
}

export default Display;