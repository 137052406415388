import React, {Component} from 'react';

import x0001 from "../shared/0x0001";
import x0002 from "../shared/0x0002";
import "./TechpageComponent.css";

function RenderArticle({article}){
    const text = article.map((paragraph)=>{
        if(paragraph.type != null){
            return (
                <h3>{paragraph.content}</h3>
            )
        } else {
            return (
                <p className="para">{paragraph.content}</p>
            )
        }
    })
    return (
        <div className="container">
            {text}
        </div>
    )
}

class TechPage extends Component{

    constructor(props) {
        super(props);

        this.state = {
            date: this.props.date,
            file: x0001    // in default
        }
        
        this.changeDate("")
    }

    changeDate(operation){
        var tempDate = this.state.date
        if (operation === "prev"){
            tempDate = tempDate - 1
            if (tempDate % 100 === 0) {
                tempDate = tempDate - 88
            }
            if (tempDate < 202012) {
                tempDate = 202012
            }
        } else if (operation === "next"){
            tempDate = tempDate + 1
            if (tempDate % 100 === 13) {
                tempDate = tempDate + 88
            }
            if (tempDate > 202101) {
                tempDate = 202101
            }
        }
        console.log("I'm called")
        this.setState({date: tempDate, file: this.loadFile(tempDate)})
    }

    loadFile(index){
        switch (index){
            case 202012:
                return x0001
            case 202101:
                return x0002
        }
    }

    addDate(){ this.changeDate("next");}
    minusDate(){ this.changeDate("prev");}

    render(){
        const date = this.props.date;
        const file = this.state.file;

        console.log(date + " " + file);
        
        if(date){
            return (
                <div>
                    <h2>{file.title}</h2>
                    <h4>{file.author}</h4>
                    <h4>{file.time}</h4>
                    <div className="row">
                        
                        <button className="col-2" onClick={()=>this.changeDate("prev")}>Prev</button>
                        <button className="col-2 offset-8" onClick={()=>this.changeDate("next")}>Next</button>
                    </div>

                    <RenderArticle article={file.article}/>
                </div>
            )
        } else {
            return(
                <div></div>
            )
        }

    }
}

export default TechPage;