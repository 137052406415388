import React from 'react';
import {Nav, Navbar, NavItem, NavLink} from 'reactstrap';

function Header(){
    return(
        <React.Fragment>
            <Navbar dark expand="xs">
                <Nav navbar className="bg-success">
                    <NavItem>
                        <NavLink className="nav-link" href="https://richardli.zone">Homepage</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className="nav-link" href="/home">Blog</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className="nav-link" href="/tech">Tech</NavLink>
                    </NavItem>
                </Nav>
            </Navbar>
        </React.Fragment>
    )
}

export default Header;