import React, {Component} from 'react';

import HomePage from './HomepageComponent';
import TechPage from './TechpageComponent';

import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom'

class Main extends Component{

    render(){
        return (
            <div className="container">
                <BrowserRouter>
                    <div className="row">
                        <Route path="/home" component={HomePage}/>
                        <Route path="/tech" component={()=><TechPage date={202101}/>}/>
                    </div>
                </BrowserRouter>
            </div>
        )
    }

}

export default Main;