const x0002 = {
    "title": "New Semester, New Areas to Explore",
    "author": "Richard Li",
    "time": "Jan 31, 2021 UTC+8:00 16:43",
    "article": [
        {
            "id": 1,
            "content": "To be honest, in this month too many new topics emerged and too many issues to care for me. " +
                "The original plan of finishing the MOOC in coursera failed due to compatibility issue and priority."
        },
        {
            "id": 2,
            "type": "subtitle",
            "content": "Courses in the new semester"
        },
        {
            "id": 3,
            "content": "In this semester, there are more practical courses than the last one. Cloud computing, Data mining," +
                " and Smart phone application development are the three courses require projects or deliverables in the form" +
                " of application."
        },
        {
            "id": 4,
            "content": "For the theory part, Quantum computing and artificial intelligence, and Topics in contemporary" +
                " database systems are literally composed and the lecture materials are going to be published by the lecturer" +
                " as new textbooks."
        },
        {
            "id": 5,
            "content": "Currently I am struck with the admission issues like python workshops or so."
        },
        {
            "id": 6,
            "type": "subtitle",
            "content": "What about previous targets?"
        },
        {
            "id": 7,
            "content": "Actually the degree requirements are very important in terms of graduation. The MOOC based " +
                "material may be visited only for the exercise part without me watching the videos. The plans are: " +
                "MongoDB and Node.JS after the mobile application course; Angular, Flutter and others after the Spark " +
                "project."
        },
        {
            "id": 8,
            "type": "subtitle",
            "content": "Plan for future?"
        },
        {
            "id": 9,
            "content": "It is quite a struggle for a graduate under the pandemic. Originally I do have thoughts about " +
                "Shenzhen and/or Singapore. I dreamt about working in German and Japan before, but due to the language issue," +
                "I am not so sure about the possibility for achieving these."
        },
        {
            "id": 10,
            "content": "I will learn the most common skill set for finding a job I think. To be a software engineer is not what I thought" +
                "when I was young. Seemingly I can have more time with computers, but it is not so fulfilling. I am in confusion for " +
                "what I have been thinking in the past few years. But things will change, right?"
        }
    ]
}

export default x0002;