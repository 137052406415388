const DEC_2020 = {
    "title": "Dec 2020",
    "author": "Richard Li",
    "content": [
        {
            'id': 1,
            'genre': 'movie',
            "text": [
                {
                    "id": 1,
                    "name": "Love Actually",
                    "desc": "A warm comedy that describes 10 pairs of love in different stories. All of them" +
                        " seem far away, but the theme of love brings them together.",
                    "rank": 5,
                    "image": "love_actually.jpg"
                }
            ]
        },
        {
            'id': 2,
            'genre': 'anime',
            "text":[
                {
                    "id": 1,
                    "name": "Love Live Nijigasaki High School Idol Club",
                    "desc": "The fifth animation series of Love Live series. Different from the previous series, " +
                        "this project focuses on the solo idol performance and the relationship of each other as friends " +
                        "as well as opponents.",
                    "rank": 4.5,
                    "image": "nijigasaki.jpeg"
                },
                {
                    "id": 2,
                    "name": "Akudama Drive",
                    "desc": "In a cyberpunk city - Kansai, dangerous criminals are requested/kidnapped to stole the good " +
                        "sending to another city - Kantou. In the progress of figuring out the truth for the two cities, " +
                        "the question gradually becomes - who are the real criminals? With cost of lives, the story ends. ",
                    "rank": 4.4,
                    "image": "akudama.jpeg"
                },
                {
                    "id": 3,
                    "name": "Osomatsu-San Season III",
                    "desc": "This season of Osomatsu-San is surrounding the topic of Artificial Intelligence -  newly introduced" +
                        " two robotic characters to discuss the existence of characters made in Shouwa age and new TV series " +
                        "in the Reiwa age.",
                    "rank": 4.7,
                    "image": "osomatsu_3.jpg"
                },
                {
                    "id" : 4,
                    "name": "Munou na Nana",
                    "desc": "A girl pretending she has the ability to read minds is assigned quests to go against threat of " +
                        "human-beings. This story progresses in a way about self-suspicion, going against self-instincts, " +
                        "and finally self-redemption.",
                    "rank": 4.8,
                    "image": "nana.jpg"
                }
            ]
        },
        {
            "id": 3,
            "genre": "game",
            "text": [
                {
                    "id": 1,
                    "name": "There is No Game - Wrong Dimension",
                    "desc": "Game, as one of the main actors, goes against users' instruction. \"Huh? " +
                        "You wanna play games? There is no game. \" ",
                    "rank": 4.9,
                    "image": "no_game.jpg"
                }
            ]
        },
        {
            "id": 4,
            "genre": "music",
            "text": [
                {
                    "id": 1,
                    "name": "Haru wa Yuku/I beg you/Hana no Uta - Aimer",
                    "desc": "A song trilogy about the anime character Sakura's love.",
                    "rank": "Listen Please"
                }
            ]
        }
    ]
};

export default DEC_2020;